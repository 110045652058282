// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import $ from 'jquery'
import 'bootstrap'

require('@rails/ujs').start()
//  require('turbolinks').start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require('slick-carousel')
require('selectric')

window.jQuery = $
window.$ = $

$(function () {
  $('select').selectric()
  window.addEventListener('orientationchange', function () {
    if (window.innerWidth > 1200) {
      window.location.reload()
    }
  }, false)

  // on page load and mobil width show only 4 items
  if (window.outerWidth < 768) {
    ShowKeyvisualOnly4Elements()
  }

  const mobileTestimonial = $('.mobile-testimonial')
  mobileTestimonial.on('swipe afterChange', function () {
    slideAnimations()
  })
  const slideAnimations = function () {
    const $slickslideActive = $('.slick-slide.slick-current.slick-active')
    const $toBeAnimated = $slickslideActive.find('.animationPending')
    $toBeAnimated.each(function () {
      const $this = $(this)
      let $animationType = $this.data('animation')
      $animationType = 'animated' + ' ' + $animationType
      $this.addClass($animationType)
      $this.removeClass('animationPending')
    })
  }
  $('#nav-burger').on('click', function () {
    $(this).toggleClass('open')
    $('.collapsing-nav').toggleClass('open')
    $('body').toggleClass('no-scroll')
  })

  //  Check Mobile Devices
  const checkMobile = function () {
    //  Check Device
    const isTouch = ('ontouchstart' in document.documentElement)

    //  Check Device //All Touch Devices
    if (isTouch) {
      $('html').addClass('touch')
    } else {
      $('html').addClass('no-touch')
    }
  }
  checkMobile()

  const stickyButtons = document.getElementById('stickyButtons')

  if (stickyButtons) {
    let scrollPosition = 0
    let ticking = false

    window.addEventListener('scroll', function (e) {
      scrollPosition = window.scrollY

      if (!ticking) {
        window.requestAnimationFrame(function () {
          ticking = false
          if (scrollPosition >= 324) {
            stickyButtons.classList.add('docked')
          } else {
            stickyButtons.classList.remove('docked')
          }
        })

        ticking = true
      }
    })
  }
  $('.keyvisual-carousel').on('destroy', function (event, slick, direction) {
    ShowKeyvisualOnly4Elements()
  })
})
window.addEventListener('resize', setWindowHeight)
window.addEventListener('load', setWindowHeight)

function setWindowHeight () {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)

  if (window.innerWidth > 1200) {
    $('#sticky-registration').css('top', $('header .nav-container').outerHeight())
  } else {
    $('#sticky-registration').css('top', 'none')
  }
}

function getRandomInt (max, exlusive) {
  var isUnique = false
  while (isUnique !== true) {
    var value = Math.floor(Math.random() * Math.floor(max))
    if (!exlusive.includes(value)) {
      isUnique = true
    } else {
      isUnique = false
    }
  }
  return value
}

function ShowKeyvisualOnly4Elements () {
  var el = $('.testimonial-link')
  el.each(function () {
    $(this).hide()
  })
  var length = el.length
  if (length > 0) {
    var exlusiveNumbers = []
    for (var x = 0; x < 4; x++) {
      var value = getRandomInt(length, exlusiveNumbers)
      exlusiveNumbers.push(value)
      console.log(value)
      el.eq(value).show()
    }
  }
}
